'use client'
import React from 'react'
import { Button } from "antd";
import Link from 'next/link'
import { Input, Form } from 'antd';
import Logo from '@/components/Logo'; // Importa tu logo


const page = () => {

    const checkLogin = async () => {
        console.log('corrio check login');
    }

    return (
        <div className="bg-white h-screen flex flex-col justify-center items-center relative">
          <header className="absolute top-5 w-[90%] flex justify-between items-center">
            <Logo /> {/* Renderiza tu logo */}
            <Link href="/login" prefetch={false} onClick={() => checkLogin()}><Button type="primary">Iniciar Sesión</Button></Link>
          </header>
    
          <main className="text-center px-12">
            <h1 className="text-6xl mb-4 text-[#106239] font-bold">Descubre SCALA</h1>
            <p className="text-lg mb-8 text-gray-700">
              El Software de Control Anti-Lavado más especializado en el mercado. Por el momento nos encontramos en beta cerrada pero próximamente estaremos abriendo el registro para usuarios como tú.
            </p>
    
            {/* <Form layout="vertical" className="max-w-sm mx-auto">
              <Form.Item label="Correo Electrónico">
                <Input placeholder="Ingresa tu correo electrónico" />
              </Form.Item>
              <Form.Item label="Código de Invitación">
                <Input placeholder="Ingresa tu código de invitación" />
              </Form.Item>
              <Button type="primary" block>SOLICITAR ACCESO</Button>
            </Form> */}
          </main>
    
          <footer className="absolute bottom-5 text-sm">
            <a href="#" className="text-[#106239] mr-4">Política de Privacidad</a>
            <a href="#" className="text-[#106239]">Términos de Servicio</a>
          </footer>
        </div>
      );
    }

export default page